'use client';

import React, { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';

interface GlobalErrorProps {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function GlobalError({ error, reset }: GlobalErrorProps) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="es">
      <body className="bg-sivarbet-background">
        <div className="h-screen flex flex-col justify-center items-center font-sans overflow-hidden">
          <h2 className="text-2xl text-white my-4 text-center">
            ¡Lo sentimos! Ocurrió un error.
          </h2>
          <p className="text-lg pb-20 max-w-3/4 text-center leading-6 text-white">
            Ocurrió un error. Por favor, intenta más tarde o contacta al soporte.
          </p>
          <button
            type="button"
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
            onClick={reset}
          >
            Reintentar
          </button>
        </div>
      </body>
    </html>
  );
}
